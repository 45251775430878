<template>
  <UiTag
    :label="themeLocale?.title"
    :to="{name: 'inspiration', query: {theme: themeLocale?.slug}}"
  />
</template>

<script lang="ts" setup>
import type {Theme} from '~/graphql/graphql';
import type {MaybeRef} from 'vue';

const props = defineProps<{
  theme: Pick<Theme, 'locales'>
}>();

const themeLocale = useLocale(toRef(props, 'theme'));

function useLocale<T extends {locales: Array<{locale: string}>}>(ref: MaybeRef<T>) {
  const {locale} = useI18n();

  return computed(() => toValue(ref).locales.find(({locale: _locale}) => _locale === locale.value));
}
</script>
